<template>
  <div class="header">
   <div class="logo text-nowrap">
      <img width="100" :src="logo"/>
      <div class="border-left padding-left-10 margin-left-10 bold text-nowrap">价格管理平台</div>
      <div v-if="env!='production'">（测试环境）</div>
   </div>
   <div class="header-main margin-left-10">
     <div class="nav">
        <div v-for="(item,index) in navByAuth(this.mainNav)" :key="index" :class="{'active':$route.matched.some(val=>{return val.name==item.name})}" 
        :data-path="item.path" @click="jump($event)">
          <span class="text">{{item.title}}</span>
        </div>
     </div>
     <div class="tools text-nowrap">
        <div v-for="(item,index) in navByAuth(this.subNav)" :key="index" :class="{'tool-item':true, 'tool-item-active':$route.matched.some(val=>{return val.name==item.name})}"
          :data-path="item.path" @click="jump($event)">{{item.title}}</div>

        <el-popover placement="bottom-end" trigger="hover">
          <div slot="reference" class="margin-right-20 margin-left-20 link hover-opacity">
            <el-avatar :size="28" icon="el-icon-user-solid" style="display:block"
              :src="avatar"
            ></el-avatar>
          </div>
          <div v-if="$store.state.loginRes.token">
            <div class="flex-row justify-between align-items-center margin-top-5">
              <div class="text-nowrap margin-right-5">你好，{{$store.state.loginRes.userName}}</div>
              <span class="link-text" @click="logout">{{$store.state.loginByBefar?'刷新权限':'退出'}}</span>
            </div>
            <el-divider></el-divider>
            <div class="flex-row justify-between align-items-center">
              <span class="color-sub">使用说明：</span>
              <span class="link-text" data-path="/doc" @click="jump($event,'_blank')">查看</span>
            </div>
            <el-divider></el-divider>
            <div class="flex-row justify-between align-items-center">
              <span class="color-sub">主题模式：</span>
              <el-switch
                @change="toggleTheme"
                v-model="valueTheme"
                active-value="dark"
                inactive-value="light"
                inactive-color="#ccc"
                active-text="深色"
                inactive-text="浅色">
              </el-switch>
            </div>
            
          </div>
        </el-popover>
     </div>
   </div>

   <div class="video link color-ra-sub" @click="video.visible=true">
      <div class="color-blue font-size-20"><i class="el-icon-video-play"></i></div>
      <div class="font-size-12">操作<Br></Br>视频</div>
    </div>
    <el-drawer
      title="操作视频"
      append-to-body
      :visible.sync="video.visible"
      direction="rtl"
      :size="400">
      <div class="margin-lr-10">
        <el-table :data="video.list" :show-header="false">
          <el-table-column>
            <template slot-scope="props">{{props.row.vidioName}}</template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="props"><el-button icon="el-icon-video-play" size="mini" @click="gotoVideo(props.row.path)">查看</el-button></template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import avatar from "@/assets/images/photo.png";
import logo from "@/assets/images/logo.png";
export default {
  name: 'Header',
  components: {

  },
  data() {
    return {
      env:process.env.NODE_ENV,//运行环境
      logo:logo,
      avatar:avatar,
      valueTheme:this.$store.state.theme,
      mainNav:[
        {title:'首页',name:'home',path:"/home",moduleCode:'' },
        {title:'目标分析',name:'targetAnalysis',path:"/targetAnalysis",moduleCode:'mu_biao_fen_xi' },
        {title:'预测模型',name:'forecast',path:"/forecast",moduleCode:'yu_ce_mo_xing' },
        {title:'自定义模型',name:'customModel',path:"/customModel/build",moduleCode:'zi_ding_yi_mo_xing' },
        {title:'预测应用',name:'forecastapply',path:"/forecastapply",moduleCode:'yu_ce_ying_yong' },
        {title:'结果查看',name:'results',path:"/results",moduleCode:'jie_guo_cha_kan' },
      ],
      subNav:[
        {title:'专家赋值',name:'expert',path:"/expert",moduleCode:'zhuan_jia_fu_zhi' },
        {title:'外生因子赋值',name:'outDivisors',path:"/outDivisors",moduleCode:'wai_sheng_yin_zi_fu_zhi' },
        
        {title:'模型管理',name:'modelManage',path:"/modelManage",moduleCode:'mo_xing_guan_li' },
        {title:'数据管理',name:'datamanage',path:"/datamanage",moduleCode:'shu_ju_guan_li' },
        {title:'权限配置',name:'powermanage',path:"/powermanage",moduleCode:'quan_xian_guan_li' },
        
      ],
      video:{
        list:[],
        visible:false
      }
    }
  },
  computed:{
    navByAuth(){
      return (res)=>{
        let arr=[]
        for(let item of res){
          if(!item.moduleCode||(this.$store.state.loginRes.moduleList||[]).find((v)=>{return v.moduleCode==item.moduleCode})){
            arr.push(item)
          }
        }
        return arr
      }
    }
  },
  methods:{
    jump: function(event,_blank){
      let path = event.currentTarget.dataset.path
      if(_blank){
        let routeData = this.$router.resolve({ 
          path:path
        });
        window.open(routeData.href, '_blank');   
      }else{
        if(path == this.$route.path){
          this.$router.replace({
                path: '/redirect',
                query: {path}
            })
        }else {
          this.$router.push({
            path: path
          })
        }
        }
    },
    
    logout(){
      if(this.$route.matched.some(record => record.meta.befarLoginAuth)&&this.$store.state.loginByBefar){
        try{
          localStorage.removeItem("loginResBefar");
          localStorage.removeItem("loginStampBefar");
        }catch{
          //
        }
        window.location.replace(`${this.$server}/login?state=${encodeURIComponent('/')}`)
      }else{
        this.$confirm('您要退出登录吗?', '退出登录', {
          customClass:"custom-msgbox",
          confirmButtonText: '继续退出',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          try{
            localStorage.removeItem("loginResBefar");
            localStorage.removeItem("loginStampBefar");
          }catch{
            //
          }
          this.$router.replace({
            path: '/login',
          })
        })
        .catch(() => {});
      }
      
    },

    //切换主题
    toggleTheme:function(){
      try{
          localStorage.setItem('theme',this.valueTheme)
      }catch{
          return
      }
      this.$store.commit('theme', this.valueTheme)
      window.document.documentElement.setAttribute( "data-theme", this.$store.state.theme );
    },
    //获取操作视频列表
    getVideoList(){
      let request=this.$instance.get('/instruction/getVideoList',{
          params: {
           ...this.$store.state.basicParams,
          },
      })
      request.then(res=>{
        if(res.data.code==0){
          this.video.list=res.data.info
        }else{
          this.video.list=[]
        }
      })
      request.catch(()=>{
        this.video.list=[]
      })
      return request
    },
    gotoVideo(url){
      window.open(url)
    }
  },
  created(){
    this.getVideoList()
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  @import './header.scss';
  .video{
    @include themeify{
        background:themed('columnBackground')!important;
    };
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border-radius: 5px 0 0 5px/5px 0 0 5px;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 99;
    padding: 5px;
  }
</style>
<style>
html[data-theme=dark] .header{
    background: #283142;
}
.header .el-switch__label.is-active{
  color: #aeceff;
}
.header .el-switch__label{
  color: #fff;
}
</style>