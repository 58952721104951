<template>
  <div>
    <Header class="header"></Header>
    <keep-alive><router-view v-if="$route.matched.some(val=>{return val.meta.keepalive})" class="container"></router-view></keep-alive>
    <router-view v-if="!$route.matched.some(val=>{return val.meta.keepalive})" class="container"></router-view>
  </div>
</template>

<script>
import Header from '../components/header/Header.vue'
export default {
  components: { Header },
  name: 'Default',
  data() {
    return {
      
    }
  },
  methods:{

  },
  mounted(){

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header{
    position: relative;
    z-index: 2;
    overflow: auto;
  }
  .container{
    width: 100%;
    height: calc(100vh - 55px);
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
  }
</style>
